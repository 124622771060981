// src/Login.js
import React, { useEffect, useState } from 'react';
import { Container, Card, Form, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Login.css'; // Import your CSS file for styling
import { useNavigate } from 'react-router-dom';

import Swal from 'sweetalert2';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const [bg,setbg]=useState('');
  useEffect(() => {
    let jj = 0
    const fetchinfo = async () => {
      try {
        const response = await fetch('https://snapus.pythonanywhere.com/api/info/4/');
        const result = await response.json();
        setbg(result.webbg)
        // Assuming the response is in JSON format
        
  
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchinfo();
  }, []);
  

  const handleLogin = async () => {
     console.log(username);
     console.log(password);
      const url = 'https://snapus.pythonanywhere.com/api/login/';
      const data = {
        username: username,
        password: password,
      };
  
      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        });
  
        if (response.ok) {
          const result = await response.json();
          navigate('/home')

        } else {
          
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
          customClass: {
            confirmButton: 'swal-button--confirm',
          },
        }).then( console.error(response))
         
        }
      } catch (error) {
        console.error('Error:', error);
      }
    
   

   
  
  };

  return (
    <div className="login-background" style={{backgroundImage:`url(${bg})`,backgroundSize:"cover",backgroundRepeat:'no-repeat',backgroundPosition:'center'}}>
      <Container className="d-flex justify-content-center align-items-center h-100">
        <Card>
          <Card.Body>
            <h2 className="text-center mb-4" style={{ letterSpacing: 6, opacity: 0.8 }}>LOGIN</h2>
            <Form>
              <Form.Group className="mb-3 fw-bold" style={{ letterSpacing: 2, opacity: 0.8 }} controlId="formBasicUsername">
                <Form.Label>USERNAME</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </Form.Group>

              <Form.Group className="mb-3 fw-bold" style={{ letterSpacing: 2, opacity: 0.8 }} controlId="formBasicPassword">
                <Form.Label>PASSWORD</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Enter your password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Form.Group>
              <Button variant="dark" className='w-100 fs-4' type="button" onClick={handleLogin}>
                Login
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
};

export default Login;
