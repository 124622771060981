import React from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import { useParams } from "react-router-dom";
import image1 from "../logo.svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faStar, faHeart } from '@fortawesome/free-solid-svg-icons';
import { faHeart as faHeartre } from "@fortawesome/free-regular-svg-icons";
import Swal from "sweetalert2";
import "./card.css"
import { isDisabled } from '@testing-library/user-event/dist/utils';


function Cards({ data }) {
  const admintext = 'Adminsecret';
  const navigate = useNavigate();
  const commentRef = useRef();
  const [commentdata, setcommentdata] = useState('');

  const [liked, setLiked] = useState();

  const [likecount, setlikecount] = useState(2)
  const [commentcount, setcommentcount] = useState(0);
  const [commentlock, setcommentlock] = useState()
  const originalDate = new Date(data.created_at);

  const day = originalDate.getDate().toString().padStart(2, "0");
  const month = (originalDate.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
  const year = originalDate.getFullYear().toString().slice(-4); // Get the last two digits of the year

  const formattedDate = `${day}-${month}-${year}`;

  const getcommentdata = async () => {

    if (commentlock == false) {
      try {
        const url = `https://snapus.pythonanywhere.com/api/comments/create/${data.id}/`;
        const postData = {
          author: admintext,
          text: commentdata,
        };

        // Use optional chaining to handle undefined commentdata
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(postData),
        });
        if (response.status === 201) {
          setcommentdata('');
          setcommentcount(commentcount + 1)
        } else {
          console.log('Failed to post the comment.');
        }
        // ... rest of the code
      } catch (error) {
        console.error('An error occurred:', error);
      }
    }
  };




  const handleLikedecrease = async () => {
    setLiked(false);

    const url = `https://snapus.pythonanywhere.com/api/comment/adliske/${data.id}/`;

    try {
      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          // You can add any additional headers if required
        },
        // You can add a request body if needed
      });

      if (response.ok) {
        console.log('PUT request successful');
        likecount > 0 && setlikecount(likecount - 1);
        decreaselikes()
      } else {
        console.error(`PUT request failed with status ${response.status}`);
        console.error(await response.text());
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleLikeincrease = async () => {
    setLiked(true);

    const url = `https://snapus.pythonanywhere.com/api/comment/adliske/${data.id}/`;

    try {
      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          // You can add any additional headers if required
        },
        // You can add a request body if needed
      });

      if (response.ok) {
        increaselikes()
        console.log('PUT request successful');
        setlikecount(likecount + 1);
      } else {
        console.error(`PUT request failed with status ${response.status}`);
        console.error(await response.text());
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };


  const increaselikes = () => {
    // Calculate updated like count

    var url = `https://snapus.pythonanywhere.com/api/like/create/${data.id}/`;

    fetch(url, {
      method: 'PUT', // Use 'PUT' to match the Python code's HTTP method
    })
      .then(response => {
        if (response.status === 200) {
          console.log(data.id, 'increase');
          return response.json();
        } else {
          throw new Error("Failed to increase likes");
        }
      })
      .then(data => {
        const numLikes = data.num_likes;


      })
      .catch(error => {
        console.error(error.message);
      });
  };

  // Function Handle Decrease Num Like 
  const decreaselikes = () => {
    // Calculate updated like count

    var url = `https://snapus.pythonanywhere.com/api/dislike/create/${data.id}/`;

    fetch(url, {
      method: 'PUT', // Use 'PUT' to match the Python code's HTTP method
    })
      .then(response => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error("Failed to decrease likes");
        }
      })
      .then(data => {
        const numLikes = data.num_likes;


      })
      .catch(error => {
        console.error(error.message);
      });
  };

  useEffect(() => {
    // Fetch initial data when the component mounts
    setlikecount(data.num_likes);
    setcommentcount(data.comments.length);
    setcommentlock(data.isDisable);
    setLiked(data.isAdminLiked)
    console.log(data)
  }, [data]);

  return (
    <Card
      bg={'Dark'.toLowerCase()}
      key={'Dark'}
      text={'Dark'.toLowerCase() === 'light' ? 'dark' : 'white'}
      style={{ display: 'flex', flexDirection: 'row', width: '100%' }}
      className="mb-2 card_main"

    >
      <Row className='d-flex align-items-center'>
        <Col lg={4}>
          <Card.Img
            variant="top"
            style={{ height: '20rem', objectFit: 'fill', width: '100%', padding: '1rem' }}
            src={data.image}

          />
        </Col>

        <Col lg={8}>
          <Card.Body style={{ flex: '1' }}>
            <h4 style={{ fontWeight: 300, letterSpacing: 3 }}>Some Details</h4>
            <Row>
              <Col lg={12} xs={6}>
                <Card.Text>Author:<span style={{ color: '#9da7af' }}>{data.author}</span></Card.Text>

              </Col>
              <Col lg={12} xs={6}>
                <Card.Text >
                  <span style={{ color: 'white', opacity: 1 }}>Description</span>: <span style={{ color: '#9da7af' }}>{data.title == 'undefined'?'none':data.title}</span>
                </Card.Text>
              </Col>
              <Col lg={12} xs={6}>
                <Card.Text>Number Of Likes:<span style={{ color: '#9da7af' }}> {likecount}</span></Card.Text>
              </Col>
              <Col lg={12} xs={6}>
                <Card.Text>Comments:<span style={{ color: '#9da7af' }}> {commentcount}</span></Card.Text>
              </Col>
              <Col lg={12} sm={6} xs={12}>
                <Card.Text>published in:<span style={{ color: '#9da7af' }}> {formattedDate}</span></Card.Text>
              </Col>
              <Col lg={12}>
                <Button variant="secondary" className="mb-2" onClick={() => navigate(`modify/${data.id}`)}>View Details</Button>
              </Col>
              <hr />
              <Card.Text>
                <h4 style={{ fontWeight: 300, letterSpacing: 3 }} className='text_comm'>Add comment </h4>
                <Row className="d-flex align-items-center">
                    <Col lg={10}>
                      <FloatingLabel controlId="floatingTextarea" label={commentlock == true ? 'Comment locked' : "Comment"} className="mb-3">
                        <Form.Control as="textarea"
                          disabled={commentlock == true && true}
                          placeholder="Leave a comment here" ref={commentRef}
                          value={commentdata} onChange={(e) => setcommentdata(e.target.value)} />
                      </FloatingLabel>
                    </Col>
                    <Col  lg={2} className="d-flex justify-content-between align-items-center p-0">
                      <button href="#" className="btn btn-secondary addbtn-tool h-100 m-2" onClick={getcommentdata} >
                        ADD
                      </button>
                      {liked == false ? <FontAwesomeIcon icon={faHeartre} className="p-2" onClick={handleLikeincrease} style={{ fontSize: 24 }} />
                        : <FontAwesomeIcon icon={faHeart} className="p-2" onClick={handleLikedecrease} style={{ fontSize: 24 }} />}
                    </Col>
                </Row>
              </Card.Text>
            </Row>
          </Card.Body>
        </Col>
      </Row>
    </Card>
  );
}

export default Cards;
