


import React, { useEffect, useState } from "react";
import LazyLoad from "react-lazyload";
import Cards from "../component/Card";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import styled from 'styled-components';

const StyledApp = styled.div`
  position: relative;
  min-height: 100vh;
  &:before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(${props => props.bg});
    background-size: cover;
    background-position: center;
    z-index: -1;
  }
`;
function Images() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();
  const [bg,setbg]=useState('');
  useEffect(() => {
    let jj = 0
    const fetchinfo = async () => {
      try {
        const response = await fetch('https://snapus.pythonanywhere.com/api/info/4/');
        const result = await response.json();
        setbg(result.webbg)
        // Assuming the response is in JSON format
        
  
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchinfo();
  }, []);

  const fetchData = async () => {
    try {
      const res = await fetch("https://snapus.pythonanywhere.com/api/images-with-comments/");
      const images = await res.json();
      return images;
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error; // Rethrow the error for proper handling
    }
  };

  useEffect(() => {
    fetchData().then((images) => {
      setData(images);
      setLoading(false);
    });
  }, []);

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchQuery(value);
  };

  const renderImages = () => (
    <Row style={{ display: "flex" }}>
      {filteredData
        .sort((a, b) => b.id - a.id)
        .map((image) => (
          <Col lg={12}  key={image.id} style={{ marginBottom: 20 }}>
            <LazyLoad height={300} offset={200} once>
              <Cards data={image} />
            </LazyLoad>
          </Col>
        ))}
    </Row>
  );

  const filteredData = data.filter((image) =>
    image.author.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
<StyledApp bg={bg}>
    
        <Container className="pt-5" >
          <h1 className="text-light">ALL Images</h1>
          <input
            className="form-control w-25 my-4"
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearchChange}
          />

          {loading ? <p>Loading...</p> : renderImages()}
        </Container>
</StyledApp>
  );
}

export default Images;

