import { createBrowserRouter } from "react-router-dom";
import Homelayout from "../layouts/home";
import Images from "../pages/images";
import Modify from "../pages/Modify";
import Login from "../pages/Login";


export const routes= createBrowserRouter([
    {
        path:'/',
        element:<Login />,
    },
    {
        path:'/home',
        element:<Homelayout/>,
        children:[
            {
                path:'/home',
                element:<Images/>
            }
            ,
            {
                path:'modify/:id',
                element:<Modify/>
            }
        ]
    }
])

