import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';

function NavBar() {
  const navigate=useNavigate();
  return (
  <>
    <Navbar bg="dark" data-bs-theme="dark" >
        <Container>
          <Navbar.Brand as={Link} to='/'>DASHPORD</Navbar.Brand>
          <Navbar.Brand as={Link} to='/' onClick={()=> navigate('/login')}><Button variant='light'>LOG OUT</Button></Navbar.Brand>
        </Container>
      </Navbar>
  
  </>
  );
}

export default NavBar;