import { useEffect, useRef, useState } from "react";
import { Col, Container, Row,Button} from "react-bootstrap";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import { useNavigate, useParams } from "react-router-dom";
import image1 from "../logo.svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faTrash,faStar,faHeart, fas } from '@fortawesome/free-solid-svg-icons';
import { faHeart as faHeartre } from "@fortawesome/free-regular-svg-icons";
import Swal from "sweetalert2";


function Modify() {
  const params = useParams();
  const [data, setData] = useState(null);
  const [switchValue, setSwitchValue] = useState(true);
  const [liked, setLiked] = useState(false);
  const commentRef =useRef();
  const maincomment=useRef();
  const[comment,setcomment]=useState(0);
  const[commentdata,setcommentdata]=useState('');
  const [commentapi,setcommentapi]=useState([])
  const [imageapi,setimage]=useState()
  const admintext='Adminsecret';
  const navigate=useNavigate();
  

  const handleLikeToggle = async () => {
    setLiked(!liked);
  
    const url = `https://snapus.pythonanywhere.com/api/comment/adliske/${params.id}/`;
  
    try {
      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          // You can add any additional headers if required
        },
        // You can add a request body if needed
      });
  
      if (response.ok) {
        console.log('PUT request successful');
        {liked ==true ? decreaselikes() : increaselikes()}
      } else {
        console.error(`PUT request failed with status ${response.status}`);
        console.error(await response.text());
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
  


  const getcommentdata = async () => {
    if (switchValue) {
      try {
        const url = `https://snapus.pythonanywhere.com/api/comments/create/${params.id}/`;
        const data = {
          author: admintext,
          text: commentdata,
        };
  
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        });
  
        if (response.status === 201) {
          console.log('Comment successfully posted.');
          setcommentdata('');
          commentRef.current.value = '';
  
          // Fetch data again after posting a new comment
          fetchData().then((image) => {
            setData(image);
            setimage(image.image);
            setcommentapi(image.comments);
            setSwitchValue(image.isDisable)
          });
        } else {
          console.log('Failed to post the comment.');
        }
      } catch (error) {
        console.error('An error occurred:', error);
      }
    }
  };
  

  const handleSwitchChange = (event) => {
    setSwitchValue(!event.target.checked);
    
      const postData = async () => {
        const url = `https://snapus.pythonanywhere.com/api/comment/activity/${params.id}/`;
        try {
          const response = await fetch(url,{
            method: 'Put',
            headers: {
              'Content-Type': 'application/json',
              // You can add any additional headers if required;
            },
          });

          if (response.ok) {
            console.log('POST request successful');
          } else {
            console.error(`POST request failed with status ${response.status}`);
            console.error(await response.text());
          }
        } catch (error) {
          console.error('Error:', error);
        }
      };

      // Call the function to make the POST request
      postData();
  }
  const removeall = () => {
    Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      }).then((result) => {
        if (result.isConfirmed) {
            maincomment.current.innerHTML = ''; 
            const deleteData = async () => {
              const url = `https://snapus.pythonanywhere.com/api/comments/delete-all/${params.id}/`;
        
              try {
                const response = await fetch(url, {
                  method: 'DELETE',
                  headers: {
                    'Content-Type': 'application/json',
                    // You can add any additional headers if required
                  },
                });
        
                if (response.ok){
                  console.log('DELETE request successful');
                } else {
                  console.error(`DELETE request failed with status ${response.status}`);
                  console.error(await response.text());
                }
              } catch (error) {
                console.error('Error:', error);
              }
            };
        
            // Call the function to make the DELETE request
            deleteData();
          Swal.fire({
            title: "Deleted!",
            text: "Your file has been deleted.",
            icon: "success"
          });
        }
      });
  
    // Clear the content of main_comment
  };



  const deleteimage = ()=>{
    Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      }).then(async (result) => {
        if (result.isConfirmed) {
          const url = `https://snapus.pythonanywhere.com/api/images/delete/${params.id}/`;

          try {
            const response = await fetch(url, {
              method: 'DELETE',
              headers: {
                'Content-Type': 'application/json',
                // Add any additional headers if needed
              },
              // You can include a request body if required for the DELETE request
              // body: JSON.stringify({ /* data */ }),
            });
      
            if (response.ok) {
              navigate('/home')
              // Handle success, e.g., update state or trigger a reload
            } else {
              console.error('Error deleting image:', response.status, response.statusText);
              // Handle error, e.g., show an error message to the user
            }
          } catch (error) {
            console.error('Error:', error);
            // Handle network errors or other unexpected errors
          }
          
        }
      });
 }



 const removeComment = async (imageid,commentId) => {
  const url = `https://snapus.pythonanywhere.com/api/comments/delete/${imageid}/${commentId}/`;

  try {
    const response = await fetch(url, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        // Add any additional headers if needed
      },
    });

    if (!response.ok) {
      setcomment(comment + 1);
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    // The comment was successfully deleted from the API
    console.log('Comment deleted successfully from the API');

    // Update the state to remove the deleted comment
    setcommentapi((prevComments) =>
      prevComments.filter((comment) => comment.id !== commentId)
    );

  } catch (error) {
    console.error('Error deleting comment:', error.message);
  }
  };

  

  const fetchData = async () => {
    try {
      const res = await fetch(`https://snapus.pythonanywhere.com/api/images/${params.id}/`);
      const image = await res.json();
      return image;
    } catch (error) {
      console.error("Error fetching data:", error);
      return null;
    }
  };

  useEffect(() => {
    fetchData().then((image) => {
      setData(image);
      setimage(image.image)
      setcommentapi(image.comments)
      setSwitchValue(image.isDisable)
      setLiked(image.isAdminLiked)
    });
  }, [comment]);



  const increaselikes = () => {
    // Calculate updated like count

    var url = `https://snapus.pythonanywhere.com/api/like/create/${params.id}/`;

    fetch(url, {
      method: 'PUT', // Use 'PUT' to match the Python code's HTTP method
    })
      .then(response => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error("Failed to increase likes");
        }
      })
      .then(data => {
        const numLikes = data.num_likes;


      })
      .catch(error => {
        console.error(error.message);
      });
  };

  // Function Handle Decrease Num Like 
  const decreaselikes = () => {
    // Calculate updated like count

    var url = `https://snapus.pythonanywhere.com/api/dislike/create/${params.id}/`;

    fetch(url, {
      method: 'PUT', // Use 'PUT' to match the Python code's HTTP method
    })
      .then(response => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error("Failed to decrease likes");
        }
      })
      .then(data => {
        const numLikes = data.num_likes;


      })
      .catch(error => {
        console.error(error.message);
      });
  };


  return (
    <> 
    
    <Container>
                <Row>
                    <Col lg={4} >
                        <img src={imageapi} className="w-100 p-3"/>
                      
                    </Col>
                    <Col lg={8}  className=" p-3">
                            <div className="d-flex align-items-center w-100 mb-4">
                                    <div>
                                   
                                  <Form.Check
                                    type="switch"
                                    id="custom-switch"
                                    label={switchValue==false ? "on" : "off"}
                                    checked={switchValue==false}
                                    onChange={handleSwitchChange}
                                    style={{color:' black !important'}}
                                  />
                                    </div>
                                    
                                        <div className="w-50 text-end">
                                          <Button variant="danger" onClick={removeall}>Remove All</Button>
                                        </div>
                                        <div className="w-50 text-end">
                                            <Button variant="danger" onClick={deleteimage}>Delete Image</Button>
                                        </div>
                            </div>
                            <h2 className="me-2" style={{letterSpacing:3}}>Comments</h2>
                       <section className="ps-3" ref={maincomment}>
                       
                           {commentapi.map((ele,index)=>{
                                return (
                                     <div>
                                            <div className="d-flex justify-content-between align-items-center">
                                            <h6 className="fw-bold ">{ele.author === admintext ? "Admin" : ele.author}{ele.author === admintext && <FontAwesomeIcon icon={faStar} style={{color: "#7ddbf2"}} />}</h6>
                                             <FontAwesomeIcon icon={faTrash} style={{color: "tomato"}} onClick={() => removeComment(data.id,ele.id)}/>
                                            </div>
                                            <p  style={{color:"gray",fontSize:'14px'}}>{ele.text}</p>
                                    </div>
                                    )
                           })}
                                            
                          {/* {comment.length > 0 ? comment.map((ele) => {
                              return <p>{ele}</p>
                          }) : null}         */}
                       </section>
                    </Col>
                    
                </Row>
                </Container>
              </>
           
      
     

  );
}

export default Modify;