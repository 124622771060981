import { Outlet } from "react-router-dom";
import NavBar from "../component/NavBar";

function Homelayout(){
    return(
<>
<NavBar/>
<Outlet/>
</>
    )
}
export default Homelayout;